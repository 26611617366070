import axiox from 'axios'
import {
	Notification,
	MessageBox,
	Message
} from 'element-ui'
import Cookies from 'js-cookie'
import errorCode from '@/utils/errorCode' //错误类型

const request = axiox.create({

	baseURL: 'https://ksbmpt.hj-blks.com/prod-api', //baseURL会在发送请求的时候拼接在url参数的前面
	//baseURL: 'http://192.168.6.174:8090/',

	timeout: 50000
})

//请求拦截
//所有的网络请求都会先走这个方法
// 添加请求拦截器,所有的网络请求都会先走这个方法，我们可以在它里面为请求添加一些自定义的内容
request.interceptors.request.use(config => {
	const uuids = Cookies.get('person');
	if (uuids) {
		config.headers['uuid'] = JSON.parse(uuids).uuid

	}
	// 是否需要设置 token
	// const isToken = (config.headers || {}).isToken === false
	// console.log(isToken, 'isToken');
	// console.log(getToken(), 'getToken()');
	// if (getToken() && !isToken) {
	//   config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
	// }
	// get请求映射params参数
	if (config.method === 'get' && config.params) {
		let url = config.url + '?';
		for (const propName of Object.keys(config.params)) {
			const value = config.params[propName];
			var part = encodeURIComponent(propName) + "=";
			if (value !== null && typeof (value) !== "undefined") {
				if (typeof value === 'object') {
					for (const key of Object.keys(value)) {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						url += subPart + encodeURIComponent(value[key]) + "&";
					}
				} else {
					url += part + encodeURIComponent(value) + "&";
				}
			}
		}
		url = url.slice(0, -1);
		config.params = {};
		config.url = url;
	}
	return config
}, error => {
	console.log(error)
	Promise.reject(error)
})
// 响应拦截器
request.interceptors.response.use(
	/**
	 * 如果您想获取http信息，如标头或状态
	 * Please return  response => response
	 */

	/**
	 * 通过自定义代码确定请求状态
	 * Here is just an example
	 * 您还可以通过HTTP状态代码来判断状态
	 */
	res => {
		// 未设置状态码则默认成功状态
		const code = res.data.code || 200;
		// 获取错误信息
		const msg = errorCode[code] || res.data.msg || errorCode['default']

		// 50008: Illegal token; 50012: Other clients logged in; 401: Token expired;
		// console.log(code, 'code状态');
		if (code === 401) {
			// 重新登录
			MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
				confirmButtonText: '重新登录',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				Cookies.remove("person");
				Cookies.remove("uuid")
				Cookies.remove("Admin-Token")
				location.reload()
			}).catch(() => {

			})

		} else if (code === 500) {
			Message({
				message: msg,
				type: 'error',
				duration: 5 * 1000
			})
			return Promise.reject(new Error(msg))
		} else if (code === 402) {
			return res.data
		} else if (code !== 200) {
			Notification.error({
				title: msg
			})
			return Promise.reject('error')
			// Message({
			//   message: res.message || 'Error',
			//   type: 'error',
			//   duration: 5 * 1000
			// })
		}
		// return Promise.reject(new Error(res.message || 'Error'))
		else {
			return res.data
		}
	},
	error => {
		console.log('err' + error) // for debug
		let {
			message
		} = error;

		if (message == "Network Error") {
			message = "后端接口连接异常";
		} else if (message.includes("timeout")) {
			message = "系统接口请求超时";
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + message.substr(message.length - 3) + "异常";
		}

		Message({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)

export function get(url, params) {
	return request.get(url, {
		params
	})
}

export function post(url, data) {
	return request.post(url, data)
}

export function del(url) {
	return request.delete(url)
}

export function put(url, data) {
	return request.put(url, data)
}

export default request;