import request from '@/api/request'; // 引入封装好的request
import Cookies from 'js-cookie';
// 公告列表
function getTweetsList(data) {
	return request({
		url: '/home/examReminder',
		method: 'post',
		data,
	})
}

// 推文详情
function getTweeDetails(data) {
	// console.log(data);
	return request({
		method: "get",
		url: `/home/getTweet/${data}`,

	})
}

// 广告图列表
function productList(data) {
	// console.log(data);
	return request({
		method: "get",
		url: `/home/productList`,
		params: data
	})
}
// 获取广告图详情接口
function productById(data) {
	// console.log(data);
	return request({
		method: "get",
		url: `/home/productById?id=` + data,
		// params: data
	})
}
// 获取验证码
function digitRandomCode() {
	return request({
		url: '/ks/loginApi/digitRandomCode',
		method: 'get'
	})
}

// 查询字典数据列表
function listData(query) {
	return request({
		url: '/system/dict/data/list',
		method: 'get',
		params: query
	})
}

// 下载附件
function getDownloadFile(url) {
	// console.log(url);
	return request({
		method: "get",
		url: `${url}`,
	})
}

// 获取指引列表
function getGuideList() {
	return request({
		method: "get",
		url: `/home/operationGuide`,
	})
}

// 获取帮助列表详情
function operationGuide(data) {
	return request({
		method: "get",
		url: `/home/operationGuide/${data}`,
	})
}

//招聘 获取分页数据
function getTweePagingtList(pageNum, type, key, dwType) {
	return request({
		method: "get",
		url: `home/tweetList?pageNum=${pageNum}&pageSize=10&ywType=${type}&ywTitle=${key}&dwType=${dwType}`,
	})
}


// 热点招聘
function getHotRecruitment(data) {
	return request({
		method: "post",
		data: data,
		url: `/home/hotRecruitment`,
	})
}

// 事业单位招聘
function getUnitRecruitment(data) {
	return request({
		method: "post",
		data: data,
		url: `/home/unitRecruitment`,
	})
}
// 非单位
function getOtherRecruitment(data) {
	return request({
		method: "post",
		data: data,
		url: `/home/otherRecruitment`,
	})
}
// 市直单位招聘
function recruitmentMunicipal(data) {
	return request({
		method: "post",
		data: data,
		url: `/home/recruitmentMunicipal`,
	})
}
// 关于我们  联系我们
function richText(data) {
	return request({
		method: "get",
		data: data,
		url: `/home/richText`,
	})
}
// 获取跳转地址
function urltest(data) {
	var uuids = Cookies.get("uuid")
	return request({
		method: "get",
		params: data,
		url: `/ksUser/jump`,
		headers: {
			uuid: uuids
		}
	})
}

function login(ksUser, ksPassword, code, uuid) {
	const data = {
		ksUser,
		ksPassword,
		code,
		uuid
	}
	return request({
		url: '/ks/loginApi/login',
		method: 'post',
		data: data
	})
}
// 微信绑定
function handWxLogin(data) {

	return request({
		url: '/ks/loginApi/handWxLogin',
		method: 'post',
		data: data
	})
}
export default {
	getTweetsList,
	getTweeDetails,
	productList,
	productById,
	getDownloadFile,
	listData,
	getHotRecruitment,
	getOtherRecruitment,
	getUnitRecruitment,
	getGuideList,
	operationGuide,
	getTweePagingtList,
	digitRandomCode,
	login,
	urltest,
	richText,
	recruitmentMunicipal,
	handWxLogin,
}